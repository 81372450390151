<header mat-dialog-title class="u-flex-full">
  <span>
    Annullamento generazione Bonus
  </span>
</header>
<mat-dialog-content>
  <p>Elimina tutti i bonus generati per l'anno selezionato.</p>
  <form [formGroup]="cancelBonusForm" novalidate>
    <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <mat-select formControlName="year" placeholder="Anno">
        <mat-option *ngFor="let year of years" [value]="year">
          {{year}}
        </mat-option>
      </mat-select>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
     </div>
  </form>
  <p><strong>Attenzione: </strong>se sono già stati sbloccati i bonus per l'anno selezionato, per procedere con l'annullamento è necessario cancellare le righe di costo relative a tali bonus</p>
    <!-- {{bonusForm.value | json}} -->

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="cancelBonusForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="cancelBonus()"
      [disabled]="cancelBonusForm.pristine || !cancelBonusForm.valid">Conferma</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
