import { cancelBonus } from '../../../../store/actions/bonus.actions';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {RiskArea } from 'src/app/commons/models/risk-area.model';
import { AlertService } from 'src/app/commons/services/alert.service';
import * as BonusActions from 'src/app/store/actions/bonus.actions';
import { AppState } from 'src/app/store/reducers';
import * as BonusSelectors from '../../../../store/selectors/bonus.selectors';
import { Bonus } from 'src/app/commons/models/bonus.model';

@Component({
  selector: 'app-cancel-bonus',
  templateUrl: './cancel-bonus.component.html',
  styleUrls: ['./cancel-bonus.component.scss']
})
export class CancelBonusComponent implements OnInit {
  private unsubscribe$ = new Subject<void>();

  cancelBonusForm: FormGroup;
  bonuses: Bonus[];
  years: number[];
  constructor(private store$: Store<AppState>, private alertService: AlertService, private fb: FormBuilder, @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {

    this.store$.pipe(select(BonusSelectors.getBonuses), takeUntil(this.unsubscribe$), map(dtos => dtos ? dtos.map(dto => new Bonus(dto)) : null)).subscribe(bonuses =>{
      this.bonuses = bonuses
      this.years = bonuses.map(bonus => bonus.year)
    });
  }

  ngOnInit() {
    console.log(this.years);
    
    this._createForm();
    this.ngOnChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _createForm() {
    let group = {
      year: ["", Validators.required],
    }
    this.cancelBonusForm = this.fb.group(group);
  }

  private ngOnChanges() {
    if (this.cancelBonusForm) {
      this.cancelBonusForm.reset();
    }

  }


  cancelBonus() {
    if(this.cancelBonusForm) {
      this.store$.dispatch(BonusActions.cancelBonus({ year: this.cancelBonusForm.value.year }));
    }
  
  }

  close() {
    if (this.cancelBonusForm.pristine) {
      this.store$.dispatch(BonusActions.closeCancelBonusDialog())
    } else {
      this.alertService
        .showConfirmDialog(
          "Chiudi",
          "Ci sono modifiche non salvate. Sei sicuro di voler chiudere?"
        )
        .subscribe(result => {
          if (result) {
            this.store$.dispatch(BonusActions.closeCancelBonusDialog())
          }
        });
    }
  }

  revert() {
    this.ngOnChanges();
  }
}
