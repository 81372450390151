import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { Bonus, BonusFilters } from 'src/app/commons/models/bonus.model';
import { BonusLine, BonusLineFilters } from 'src/app/commons/models/bonus_line.model';

import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';


export type BonusLinesColumn = "user" | "project"| "client" | "costLine" | "amount"| "type";

@Component({
  selector: 'bonus-line-list',
  templateUrl: './bonus-line-list.component.html',
  styleUrls: ['./bonus-line-list.component.scss'],

})
export class BonusLineListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  bonusLines: Observable<BonusLine[]>;

  @Input()
  showProjectColumn: boolean;
  @Input()
  showCostLineColumn: boolean;

  pageSizeOptions = PAGE_SIZE_OPTIONS;


  @Input()
  defaultFilters: BonusLineFilters;

  @Input()
  total: Observable<number>;

  displayedColumns: BonusLinesColumn[] = ["user" , "project" , "client", "amount", "type", "costLine"];

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<BonusLineFilters> = new EventEmitter<BonusLineFilters>();
  @Output()
  onExportBonusLines: EventEmitter<any> = new EventEmitter<any>();


  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }
  onFilter(filters: BonusLineFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
  openBonusLines() {
    this.onExportBonusLines.emit();
  }

}
