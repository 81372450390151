import { Bonus, BonusDTO } from 'src/app/commons/models/bonus.model';
import { CostLine, CostLineDTO } from 'src/app/commons/models/cost-line.model';
import { AbstractControl } from '@angular/forms';
import { Base, BaseDTO } from './base.model';
import { Currency, CurrencyDTO } from './currency.model';
import { Project, ProjectDTO } from './project.model';
import { User, UserDTO } from './user.model';

export type BonusType = 'Client bonus' | 'Sales bonus' | 'Project bonus' | 'Advisory fee';
export const BonusTypes: BonusType[] = ['Client bonus', 'Sales bonus', 'Project bonus', 'Advisory fee'];

export interface BonusLineFilters {
    bonus?: Bonus;
    project?: Project;
  }

export interface BonusLineDTO extends BaseDTO {
    user_id: number;
    project_id: number;
    user: UserDTO;
    project: ProjectDTO;
    amount: number;
    bonus_id: number;
    bonus: BonusDTO;
    type: BonusType;
    cost_line_id: number;
    cost_line: CostLineDTO
}

export class BonusLine extends Base {

    user: User;
    project: Project;
    amount: number;
    bonus: Bonus;
    type: BonusType;
    costLine: CostLine;

    constructor(source: BonusLineDTO) {
        super(source);
        if (source) {
            if (source.user) {
                this.user = new User(source.user);
                this.addLoadedRelation('user');
            }
            if (source.bonus) {
                this.bonus = new Bonus(source.bonus);
                this.addLoadedRelation('bonus');
            }
            if (source.project) {
                this.project = new Project(source.project);
                this.addLoadedRelation('project');
            }
            if (source.cost_line) {
                this.costLine = new CostLine(source.cost_line);
                this.addLoadedRelation('cost_line');
            }
            this.amount = source.amount;
            this.type = source.type;
        }
    }

    toDTO(): BonusLineDTO {
        let result: BonusLineDTO = <BonusLineDTO>super.toDTO();
        result.user = this.user ? this.user.toDTO() : null;
        result.user_id = this.user ? this.user.id : null;
        result.bonus= this.bonus ? this.bonus.toDTO() : null;
        result.bonus_id = this.bonus ? this.bonus.id : null;
        result.amount = this.amount;
        result.type = this.type;
        result.project = this.project ? this.project.toDTO() : null;
        result.project_id = this.project ? this.project.id : null;
        result.cost_line = this.costLine ? this.costLine.toDTO() : null;
        result.cost_line_id = this.costLine ? this.costLine.id : null;

        return result;
    }

    static fromFormGroup(formGroup: AbstractControl, original?: BonusLine) {
        const formModel = formGroup.value;
        let bonusLine: BonusLine = new BonusLine(null);
        bonusLine.user = formModel.user;
        bonusLine.amount = formModel.amount;
        bonusLine.bonus = formModel.bonus;
        bonusLine.type = formModel.type;
        bonusLine.project = formModel.project;

        if (original) {
            bonusLine.id = original.id;
        }
        return bonusLine;
    }

}
