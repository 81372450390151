<header mat-dialog-title class="u-flex-full">
  <span>
     Generazione Bonus
  </span>
</header>
<mat-dialog-content>
  <form [formGroup]="bonusForm" novalidate>
    <div class="u-flex-full">
    <mat-form-field class="u-full-width">
      <mat-label>Anno</mat-label>
      <mat-select formControlName="year">
        <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
      </mat-select>
      <mat-error>Campo obbligatorio</mat-error>
    </mat-form-field>
    <mat-form-field class="u-full-width">
      <input matInput placeholder="Available bonus" formControlName="bonus" type="text" forceNumber/>
      <mat-error>Campo obbligatorio</mat-error>
      <mat-hint>Bonus totale da distribuire</mat-hint>
    </mat-form-field>
     </div>
  </form>

</mat-dialog-content>
<mat-dialog-actions class="u-flex-full">
  <span>
    <button mat-raised-button color="basic" type="reset" (click)="revert()"
      [disabled]="bonusForm.pristine">Reset</button>
    <button mat-raised-button color="primary" (click)="generateBonus()"
      [disabled]="bonusForm.pristine || !bonusForm.valid">Genera</button>
    <span class="fill-remaining"></span>
  </span>
  <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
</mat-dialog-actions>
