
<header mat-dialog-title class="u-flex-full">
    
      <span *ngIf="(bonusLines | async).length">Singoli bonus relativi all'anno <strong>{{bonus?.year}}</strong> - <strong>{{bonus.amount | customCurrency}} €</strong></span>
 
    <button *ngIf="(bonusLines | async).length" mat-icon-button mat-raised-button color="primary" 
    (click)="exportBonusLines()">
    <mat-icon matTooltip="Scarica CSV">file_download</mat-icon>
    </button>
  </header>
  <mat-dialog-content>

  <div style="margin: 0 15px;">
    <bonus-line-list [bonusLines]="bonusLines" [total]="total"  [defaultFilters]="defaultFilters" (onFilterChange)="filtersChange($event)"
    (onSortChange)="sortChange($event)" (onPageChange)="pageChange($event)" (onExportBonusLines)="exportBonusLines()"
     [showProjectColumn]="showProjectColumn" [showCostLineColumn]="showCostLineColumn"></bonus-line-list>
  </div>
  </mat-dialog-content>
  <mat-dialog-actions class="u-flex-full">
    <button mat-raised-button color="basic" type="button" (click)="close()">Chiudi</button>
  </mat-dialog-actions>

  