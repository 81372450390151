<!-- <app-bonus-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-bonus-filters> -->
<mat-card class="u-center-text" *ngIf="!(bonuses | async).length">
  <h4>Nessun bonus trovato</h4>
</mat-card>
<div class="table-container" [hidden]="!(bonuses | async).length">
  <table mat-table matSort [dataSource]="bonuses">

    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let row">
        {{row.id}}
      </td>
    </ng-container>

    <ng-container matColumnDef="year">
      <th mat-header-cell *matHeaderCellDef> Anno </th>
      <td mat-cell *matCellDef="let row">
        {{row.year }}
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> Available Bonus </th>
      <td mat-cell *matCellDef="let row">
        {{row.amount | customCurrency}}&nbsp;€
      </td>
    </ng-container>

    <ng-container matColumnDef="bonus_client">
      <th mat-header-cell *matHeaderCellDef> Bonus Cliente </th>
      <td mat-cell *matCellDef="let row">
        {{row.bonus_client}}
      </td>
    </ng-container>

    <ng-container matColumnDef="project_bonus">
      <th mat-header-cell *matHeaderCellDef> Project Bonus </th>
      <td mat-cell *matCellDef="let row">
        {{row.project_bonus}}
      </td>
    </ng-container>

    <ng-container matColumnDef="bonus_sales">
      <th mat-header-cell *matHeaderCellDef> Bonus Sales </th>
      <td mat-cell *matCellDef="let row">
        {{row.bonus_sales}}
      </td>
    </ng-container>

    <ng-container matColumnDef="margin">
      <th mat-header-cell *matHeaderCellDef> Margine di Commessa </th>
      <td mat-cell *matCellDef="let row">
        {{row.margin}}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="openBonusLines(row)"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
