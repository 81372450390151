import { state, style, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable } from 'rxjs';
import { Bonus, BonusFilters } from 'src/app/commons/models/bonus.model';

import { PAGE_SIZE_OPTIONS } from '../../../helpers/table.helper';


export type BonusesColumn = "id" | "year" | "amount" | "bonus_client" | "project_bonus" | "bonus_sales" | "margin";

@Component({
  selector: 'bonus-list',
  templateUrl: './bonus-list.component.html',
  styleUrls: ['./bonus-list.component.scss'],

})
export class BonusListComponent {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  @Input()
  bonuses: Observable<Bonus[]>;

  pageSizeOptions = PAGE_SIZE_OPTIONS;
  expandedElement: Bonus | null;

  @Input()
  defaultFilters: BonusFilters;

  @Input()
  total: Observable<number>;
  @Input()
  displayedColumns: BonusesColumn[] = ["year", "amount", "bonus_client", "project_bonus", "bonus_sales", "margin"];

  @Output()
  onLoad: EventEmitter<any> = new EventEmitter<any>();
  @Output()
  onSortChange: EventEmitter<Sort> = new EventEmitter<Sort>();
  @Output()
  onPageChange: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
  @Output()
  onFilterChange: EventEmitter<BonusFilters> = new EventEmitter<BonusFilters>();
  @Output()
  onOpenBonusLines: EventEmitter<Bonus> = new EventEmitter<Bonus>();


  constructor() { }


  ngAfterViewInit() {
    this.sort.sortChange.subscribe((sort) => {
      this.onSortChange.emit(sort);
    });
    this.paginator.page.subscribe((pageEvent) => {
      this.onPageChange.emit(pageEvent);
    });
  }
  onFilter(filters: BonusFilters) {
    this.paginator.firstPage();
    this.onFilterChange.emit(filters);
  }
  openBonusLines(bonus: Bonus) {
    this.onOpenBonusLines.emit(bonus);
  }

}
