<app-cost-line-filters (onFilter)="onFilter($event)" [defaultFilters]="defaultFilters">
</app-cost-line-filters>
<mat-card class="u-center-text" *ngIf="!costLines.length">
  <h4>Nessuna riga di costo trovata</h4>
  <!-- <button *ngIf="canAdd" mat-raised-button (click)="addCostLine()">Aggiungi</button> -->
</mat-card>
<div class="table-container" [hidden]="!costLines.length">
  <table mat-table matSort [dataSource]="costLines" multiTemplateDataRows matSortDisableClear matSortActive="date"
    matSortDirection="asc">
    <!-- <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let row">
        {{row.id}}
      </td>
    </ng-container> -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Data</th>
      <td mat-cell *matCellDef="let row">
        {{ row.date | dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="user">
      <th mat-header-cell *matHeaderCellDef>Professionista</th>
      <td mat-cell *matCellDef="let row">
        <!-- <span *ngFor="let professional of row.professionals; let i = index"
          >{{ professional?.user?.fullName
          }}<span *ngIf="i < row.professionals.length - 1">,&nbsp;</span></span
        > -->
        {{ row.professional?.user?.fullName}}
      </td>
    </ng-container>
    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Importo</th>
      <td mat-cell *matCellDef="let row" class="text-right no-wrap">
        {{ row.amount | customCurrency }}&nbsp;€
      </td>
    </ng-container>
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Commessa</th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.identifier }} - {{ row.project?.description }}
      </td>
    </ng-container>
    <ng-container matColumnDef="client">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
      <td mat-cell *matCellDef="let row">
        {{ row.project?.client?.name }}
      </td>
    </ng-container>
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Descrizione</th>
      <td mat-cell *matCellDef="let row">
        {{ row.description }}
      </td>
    </ng-container>
    <!-- <ng-container matColumnDef="activities">
      <th mat-header-cell *matHeaderCellDef> Attività </th>
      <td mat-cell *matCellDef="let row">
        {{row?.activities.join(",")}}
      </td>
    </ng-container>
    <ng-container matColumnDef="expenses">
      <th mat-header-cell *matHeaderCellDef> Spese </th>
      <td mat-cell *matCellDef="let row">
        {{row?.expenses.join(",")}}
      </td>
    </ng-container> -->
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef>Tipologia</th>
      <td mat-cell *matCellDef="let row">
        {{ row?.type }}
      </td>
    </ng-container>
    <ng-container matColumnDef="accountable">
      <th mat-header-cell *matHeaderCellDef>Pagabile</th>
      <td mat-cell *matCellDef="let row">
        <mat-icon *ngIf="row.accountable">
          done_outline
        </mat-icon>
      </td>
    </ng-container>
    <ng-container matColumnDef="invoice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="invoice_number">
        Fattura
      </th>
      <td mat-cell *matCellDef="let row">
        <span *ngIf="row.invoiceNumber">{{ row?.invoiceNumber }} <span *ngIf="row.invoiceDate">del {{row?.invoiceDate |
            date: 'dd-MM-yyyy'}}</span></span>
      </td>
    </ng-container>
    <ng-container matColumnDef="invoicePaymentDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="invoice_payment_date">
        Data pagamento fattura professionista
      </th>
      <td mat-cell *matCellDef="let row">
        {{ row?.invoicePaymentDate | dateFormat }}
      </td>
    </ng-container>
    <ng-container matColumnDef="selected">
      <th mat-header-cell *matHeaderCellDef>
        <ng-container *ngIf="!!selectionMode">
          <ng-container *showFor="['admin']">
            <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
              [checked]="selectedCostLines?.length > 0  && isAllSelected()"
              [indeterminate]="selectedCostLines?.length > 0 && !isAllSelected()">
            </mat-checkbox>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!selectionMode">
          <mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
            [checked]="(selectedCostLines?.length + selectedInvoicedCostLines?.length) > 0  && isAllSelected()"
            [indeterminate]="(selectedCostLines?.length + selectedInvoicedCostLines?.length) > 0 && !isAllSelected()">
          </mat-checkbox>
        </ng-container>
      </th>
      <td mat-cell *matCellDef="let row">
        <ng-container *ngIf="!!selectionMode">
          <ng-container *showFor="['admin']">
            <mat-checkbox color="primary" (change)="toggleSelectedCostLine(row)" [checked]="isSelected(row)"
              (click)="$event.stopPropagation()" [disabled]="!isSelectable(row)"
              [matTooltip]="!isSelectable(row) ? tooltip(row) : ''">
            </mat-checkbox>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!selectionMode">
          <mat-checkbox [disabled]="!isSelectable(row)" [color]="row.payable ? 'accent' : 'primary'"
            (change)="toggleSelectedCostLine(row)" [matTooltip]="!isSelectable(row) ? tooltip(row) : ''"
            [checked]="isSelected(row)" (click)="$event.stopPropagation()">
          </mat-checkbox>
          <!-- <ng-container *showFor="['admin']">
            <mat-checkbox *ngIf="row.payable" color="accent" (change)="toggleSelectedInvoicedCostLine(row)"
              [checked]="isInvoicedSelected(row)" (click)="$event.stopPropagation()">
            </mat-checkbox>
          </ng-container> -->
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        <button *ngIf="canAdd" mat-icon-button mat-raised-button color="accent"
          (click)="$event.stopPropagation(); exportCostLines()">
          <mat-icon>file_download</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let row">

        <button *ngIf="!canSelect" mat-icon-button [mat-menu-trigger-for]="actionMenu"
          (click)="$event.stopPropagation()">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu x-position="before" overlapTrigger="false" #actionMenu="matMenu">
          <ng-container *ngIf="canEdit">
            <button mat-menu-item (click)="editCostLine(row)">
              <mat-icon>create</mat-icon>
              Modifica
            </button>
          </ng-container>
          <ng-container *showFor="['admin']">
            <mat-divider></mat-divider>
            <ng-container *ngIf="canEdit">
              <button mat-menu-item *ngIf="!row.accountable" (click)="accountable(row)">
                <mat-icon>attach_money</mat-icon>
                Pagabile
              </button>
              <button mat-menu-item *ngIf="row.accountable" [matTooltip]="tooltip(row)"
                [disabled]="!row.accountableCancellable" (click)="accountable(row, true)">
                <mat-icon>attach_money</mat-icon>
                Non Pagabile
              </button>
            </ng-container>
          </ng-container>
        </mat-menu>
        <button *ngIf="canSelect" mat-raised-button color="primary" (click)="selectCostLine(row)">
          Seleziona
        </button>
      </td>
    </ng-container>
    <ng-container matColumnDef="expandedDetail">
      <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
        <div class="element-detail">
          <div class="element-info">
            <div *ngIf="element.activities">
              <table class="details">
                <tr>
                  <th>Data</th>
                  <th style="width: 50px">Ore</th>
                  <th style="width: 200px">Budget</th>
                  <th class="full-width">Note</th>
                </tr>
                <tr *ngFor="let activity of element.activities">
                  <td>{{activity.date | date : "dd/MM/yyyy"}}</td>
                  <td style="width: 50px">{{activity.hours}}</td>
                  <td style="width: 200px">{{activity.budget?.name}}</td>
                  <td class="full-width">{{activity.notes}}</td>
                </tr>
              </table>
              <!-- <div *ngFor="let activity of element.activities"> -->
              <!-- <app-detail label="Data" [value]='activity.date | date : "dd/MM/yyyy"'></app-detail>
                <app-detail label="Ore" [value]="activity.hours"></app-detail>
                <app-detail label="Budget" [value]="activity.budget?.name"></app-detail>
                <app-detail label="Note" [value]="activity.notes"></app-detail> -->
              <!-- <p *ngIf="activity.budget">
                  Budget: {{ activity.budget?.name }}
                </p>
                <p *ngIf="activity.professional">
                  Professionista: {{ activity.professional?.user?.name }}
                  {{ activity.professional?.user?.lastname }}
                </p>
                <p *ngIf="activity.date">
                  Data: {{ activity.date | date : "dd/MM/yyyy" }}
                </p>
                <p *ngIf="activity.hours">Ore: {{ activity.hours }}</p>
                <p *ngIf="activity.notes">Note: {{ activity.notes }}</p> -->
              <!-- </div> -->
            </div>
            <div *ngIf="element.expenses">
              <table class="details">
                <tr>
                  <th>Data</th>
                  <th>Tipologia</th>
                  <th style="width: 150px">Importo</th>
                  <th class="full-width">Note</th>
                </tr>
                <tr *ngFor="let expense of element.expenses">
                  <td>{{expense.date | date : "dd/MM/yyyy"}}</td>
                  <td>{{expense.type}}</td>
                  <td style="width: 150px" class="text-right no-wrap">{{expense.amount | customCurrency}}&nbsp;€</td>
                  <td class="full-width">{{expense.notes}}</td>
                </tr>
              </table>
              <!-- <div *ngFor="let expense of element.expenses">
                <app-detail label="Data" [value]='expense.date | date : "dd/MM/yyyy"'></app-detail>
                <app-detail label="Tipologia" [value]="expense.type"></app-detail>
                <app-detail label="Importo" [value]="(expense.amount | customCurrency) + ' €'"></app-detail>
                <app-detail label="Note" [value]="expense.notes"></app-detail> -->
              <!-- <p *ngIf="expense.amount">
                  Importo: {{ expense.amount | customCurrency }}&nbsp;€
                </p>
                <p *ngIf="expense.professional">
                  Professionista: {{ expense.professional?.user?.name }}
                  {{ expense.professional?.user?.lastname }}
                </p>
                <p *ngIf="expense.date">
                  Data: {{ expense.date | date : "dd/MM/yyyy" }}
                </p>
                <p *ngIf="expense.type">Tipologia: {{ expense.type }}</p>
                <p *ngIf="expense.notes">Note: {{ expense.notes }}</p> -->
              <!-- </div> -->
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; columns: displayedColumns" class="element-row"
      (click)="expandedElement = expandedElement === element ? null : element"></tr>
    <tr mat-row *matRowDef="let element; columns: ['expandedDetail']"
      [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="pageSizeOptions" [pageSize]="pageSizeOptions[0]" [length]="total | async"
    showFirstLastButtons>
  </mat-paginator>
</div>
<mat-expansion-panel [expanded]="true" *ngIf="selectedCostLines?.length">
  <div class="u-flex-full">
    <h4 class="u-full-width ">
      {{(totalExpenses + totalActivities)| customCurrency}}&nbsp;€ di cui {{totalActivities | customCurrency}}&nbsp;€
      (Attività) e {{totalExpenses | customCurrency}}&nbsp;€ (Spese)
    </h4>
    <div class="button-container">
      <ng-container *ngIf="!selectionMode">
        <button *ngIf="selectedCostLines?.length" mat-raised-button color="primary" (click)="insertInvoice()">
          Imposta dati di fatturazione
        </button>
        <ng-container *showFor="['admin']">
          <button *ngIf="selectedInvoicedCostLines?.length" mat-raised-button color="accent" (click)="insertPayment()">
            Imposta pagamento al professionista
          </button>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedCostLines?.length">
        <span [ngSwitch]="selectionMode">
          <button *ngSwitchCase="'not_accountable'" mat-raised-button color="primary"
            (click)="onMultipleAccountable.emit(false)">Pagabile</button>
          <button *ngSwitchCase="'accountable'" mat-raised-button color="primary"
            (click)="onMultipleAccountable.emit(true)">Non pagabile</button>
        </span>
      </ng-container>
    </div>
  </div>
</mat-expansion-panel>