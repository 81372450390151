import { Action, createReducer, on } from '@ngrx/store';

import { Bonus, BonusDTO, BonusFilters } from '../../commons/models/bonus.model';
import * as BonusActions from '../actions/bonus.actions';

export interface BonusState {
  list: BonusDTO[],
  total: number,
  includes: string[],
  currentPage: number,
  perPage: number,
  order: string,
  direction: string,
  filters: BonusFilters,
  dialogId: string,
  cancelDialogId: string,
  bonus: Bonus,
  bonusLineDialogId: string;
};

const initialState: BonusState = {
  list: [],
  total: 0,
  includes: [],
  currentPage: 1,
  perPage: 25,
  order: null,
  direction: null,
  filters: null,
  dialogId: null,
  cancelDialogId: null,
  bonus: null,
  bonusLineDialogId: null
};

const bonusReducer = createReducer(
  initialState,
  on(BonusActions.loadBonusesCompleted, (state, { bonuses, currentPage, total, perPage, order, direction, filters, includes }) => {
  return { ...state, list: bonuses, currentPage, total, perPage, order, direction, filters, includes };
  }),
  on(BonusActions.bonusDialogOpened, (state, { dialogId }) => {
    return { ...state, dialogId };
  }),
  // on(BonusActions.changeFilters, (state, { filters }) => {
  //   return { ...state, currentPage: 1, filters };
  // }),
  on(BonusActions.CancelBonusDialogOpened, (state, { cancelDialogId }) => {
    return { ...state, cancelDialogId };
  }),
  on(BonusActions.bonusLinesDialogOpened, (state, {  bonusLineDialogId }) => {
    return { ...state, bonusLineDialogId };
  })
);

export function reducer(state: BonusState | undefined, action: Action) {
  return bonusReducer(state, action);
}

